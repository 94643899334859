import React, { useState, useRef } from 'react';

import SelectedPin from '../images/selected-pin.svg';
import Pin from '../images/pin.svg';
import Layout from '../components/layout';
import Logo from '../components/logo';
import Menu from '../components/menu';
import Section, { InnerSection } from '../components/section';
import Slider from '../components/slider';
import Footer from '../components/footer';
import { ModalContext } from '../components/context/Modal';
import ListItem from '../components/list-item';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import Properties from '../utils/properties';

const defaultMapLocation = { lat: 39.983077, lng: -82.993137 };

function updatePropertyHoverState(props, propertyId, hoverState) {
  // Deep clone...
  const properties = JSON.parse(JSON.stringify(props));
  // Reset all hovering to false
  properties.forEach(property => {
    property.map.hovering = false;
  });
  // Update one that needs updated
  const propToUpdate = properties.find(property => property.id === propertyId);
  propToUpdate.map.hovering = hoverState;
  return properties;
}

const MapComp = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        ref={props.mapRef}
        defaultZoom={9.5}
        defaultCenter={defaultMapLocation}
        center={defaultMapLocation}
      >
        {props.properties.map(property => {
          return (
            <Marker
              onMouseOver={() => {
                props.updateProperties(
                  updatePropertyHoverState(props.properties, property.id, true)
                );
              }}
              onMouseOut={() => {
                props.updateProperties(
                  updatePropertyHoverState(props.properties, property.id, false)
                );
              }}
              position={{ lat: property.map.lat, lng: property.map.long }}
              icon={property.map.hovering ? SelectedPin : Pin}
            />
          );
        })}
      </GoogleMap>
    );
  })
);

export default function IndexPage(props) {
  const mapRef = useRef(null);
  const [properties, updateProperties] = useState(Properties);
  const [zoom, setZoom] = useState(9.5);

  return (
    <Layout>
      <div className="header sub-page communities-header">
        <Menu {...props} />
        <div className="hero-section sub-page">
          <span className="tagline">Our Communities</span>
          <span className="sub-header">
            We have the breadth of experience to properly manage assets from
            Value Oriented to Luxury Upscale communities from Turn Around
            Projects to New Development and Stabilization.
          </span>
        </div>
      </div>
      <div className="body-container sub-page communities">
        <Section
          title="Tailored to Each Community"
          body="We take time to understand the make-up of the community and its role in the surrounding area and tailor it’s operation accordingly.  There is a saying in the business that states: as the office makeup goes so goes the makeup of the community.  We take time to match the right staff with the right personality of the community."
          backgroundName="communities"
          left
        />
        <div className="map-container">
          <div className="map">
            <MapComp
              mapRef={mapRef}
              zoom={zoom}
              properties={properties}
              updateProperties={updateProperties}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyANMSBtkBWHGRs8AToAJGIIiPKr450Uxb4&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          <div className="community-list">
            {properties.map(property => {
              return (
                <ListItem
                  name={property.name}
                  location={property.location}
                  image={property.image}
                  features={property.features}
                  url={property.url}
                  hovering={property.map.hovering}
                  onMouseEnter={() => {
                    mapRef.current.panTo(defaultMapLocation);
                    mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                      9.5
                    );
                    setTimeout(() => {
                      mapRef.current.panTo({
                        lat: property.map.lat,
                        lng: property.map.long,
                      });
                      mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                        11.5
                      );
                    }, 250);
                    updateProperties(
                      updatePropertyHoverState(properties, property.id, true)
                    );
                  }}
                  onMouseLeave={() => {
                    mapRef.current.panTo(defaultMapLocation);
                    mapRef.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                      9.5
                    );
                    updateProperties(
                      updatePropertyHoverState(properties, property.id, false)
                    );
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
