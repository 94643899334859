import uuid from 'uuid/v4';

function createProperty(
  name,
  location,
  image,
  features,
  url,
  { currentProperty, residential, retail, office },
  { lat, long }
) {
  return {
    id: uuid(),
    name,
    location,
    image,
    features,
    url,
    map: {
      lat,
      long,
      hovering: false,
    },
  };
}

export default [
  createProperty(
    'Stratford Park Apartment Homes',
    'Delaware, OH',
    require('src/images/communities/stratford.jpg'),
    ['80 1 and 2 Bedroom Garden Apartments', '8.1 acres', 'Built in 2000'],
    'http://stratfordparkapts.com/',
    { currentProperty: true, residential: true, retail: false, office: false },
    { lat: 40.275556, long: -83.066517 }
  ),
  createProperty(
    'The Cove at Cooper Lakes',
    'Westerville, OH',
    require('src/images/communities/coveatcooperlakes.jpg'),
    [
      '240 1 and 2 bedroom garden apartments',
      '21 acres, 3 large ponds',
      'Built in 1998',
    ],
    'https://www.cooperlakes.com/',
    { currentProperty: true, residential: true, retail: false, office: false },
    { lat: 40.09103, long: -82.934854 }
  ),
  createProperty(
    'Chantry Village Apartment Homes',
    'Columbus, OH',
    require('src/images/communities/chantry.jpg'),
    [
      '240 1, 2, and 3 Bedroom Garden and Townhome Apartments',
      '20 acres, 2 large ponds',
      'Built in 2006',
    ],
    'https://www.chantryvillage.com/',
    { currentProperty: true, residential: true, retail: false, office: false },
    { lat: 39.926082, long: -82.839784 }
  ),
  createProperty(
    'Charter Club Apartments',
    'London, OH',
    require('src/images/communities/charterclub.jpg'),
    ['80 1 and 2, Bedroom Garden Apartments', '10 acres', 'Built in 1999'],
    'http://www.charterclubapts.com/',
    { currentProperty: true, residential: true, retail: false, office: false },
    { lat: 39.903959, long: -83.434063 }
  ),
  createProperty(
    'Sheffield Manor Apartment Homes',
    'London, OH',
    require('src/images/communities/sheffield.jpg'),
    ['56 1 and 2 Bedroom Garden Apartments', '6.55 acres', 'Built in 1998'],
    'https://www.apartments.com/sheffield-manor-london-oh/gs14vkw/',
    { currentProperty: true, residential: true, retail: false, office: false },
    { lat: 39.900101, long: -83.438916 }
  ),
];
